import React, { useState, useContext } from "react";
import { api } from "./api";

// components
import { message } from "antd";

// types
import { ILogin, IData, IState, IUpdateUser } from "../types/type-defs";
import { CreateUser } from "types";

interface IProps {
  children: React.ReactNode;
}

const DataCtx = React.createContext<IData>({
  loggedUser: "",
  state: {
    users: [],
    places: [],
    links: [],
  },
  login: () => {},
  logout: () => {},
  setState: () => {},
  getUsers: async () => [],
  deleteUser: async () => {},
  updateUser: async () => {},
  createUser: async () => {},
  getPlaces: async () => {},
  getLinks: async () => {},
  createLink: async () => {},
  deleteLink: async () => {},
  getMetadata: async () => {},
  convertMbtiles: async () => {},
});

export const DataProvider: React.FC<IProps> = ({ children }) => {
  const user = localStorage.getItem("user") ?? "";

  const [loggedUser, setLoggedUser] = useState(user);
  const [state, setState] = useState<IState>({
    users: [],
    places: [],
    links: [],
  });

  const handleError = (resp: Response) => {
    if (resp.status === 401) {
      logout();
    } else {
      message.error("Что-то пошло не так...");
    }
  };

  const login = ({ username, password }: ILogin) =>
    api.login({ username, password, setLoggedUser, handleError });
  const logout = () => api.logout(setLoggedUser);
  const getUsers = () => api.getUsers(handleError);
  const deleteUser = (userId: number) =>
    api.deleteUser({ userId, handleError });
  const updateUser = ({ userId, user }: IUpdateUser) =>
    api.updateUser({ userId, user, handleError });
  const createUser = (user: CreateUser) =>
    api.createUser({ user, handleError });
  const getPlaces = () => api.getPlaces(handleError);
  const getLinks = () => api.getLinks(handleError);
  const createLink = (place_id: number) =>
    api.createLink(place_id, handleError);
  const deleteLink = (place_id: number) =>
    api.deleteLink(place_id, handleError);
  const getMetadata = (mbtiles: FormData) =>
    api.getMetadata(mbtiles, handleError);
  const convertMbtiles = (formdata: FormData) =>
    api.convertMbtiles(formdata, handleError);

  return (
    <DataCtx.Provider
      value={{
        loggedUser,
        state,
        setState,
        login,
        logout,
        getUsers,
        deleteUser,
        updateUser,
        createUser,
        getPlaces,
        getLinks,
        createLink,
        deleteLink,
        getMetadata,
        convertMbtiles,
      }}
    >
      {children}
    </DataCtx.Provider>
  );
};

export const useStore = () => {
  const context = useContext(DataCtx);
  if (context === undefined) {
    throw new Error("useStore must be used within a DataCtx");
  }
  return context;
};

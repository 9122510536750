import { useState } from "react";

// components
import { Button, notification, Upload, Form } from "antd";
import { UploadOutlined } from "@ant-design/icons";

// styles
import styles from "./TilesPage.module.css";

// utils
import { useStore } from "utils/store";

// types
import { RcFile, UploadFile } from "antd/lib/upload";
import { IMetadata } from "types/type-defs";

interface IProps {
  fileList: Array<RcFile>;
  setFileList: React.Dispatch<React.SetStateAction<RcFile[]>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setMetadata: React.Dispatch<React.SetStateAction<IMetadata | null>>;
}

type IData = {
  bounds: string;
  center: null | string;
  description: string;
  maxzoom: number;
  minzoom: number;
  name: string;
};

const convertMetadata = (data: IData) => {
  const { bounds, name, minzoom, maxzoom } = data;
  const coords = bounds?.split(",").map((i) => parseFloat(i));

  if (!bounds) {
    return {
      name,
      minzoom,
      maxzoom,
      latitude: 61.78284,
      longitude: 34.36628,
    };
  }

  return {
    name,
    minzoom,
    maxzoom,
    latitude: (coords[1] + coords[3]) / 2,
    longitude: (coords[0] + coords[2]) / 2,
  };
};

export const ImportForm: React.FC<IProps> = ({
  fileList,
  setFileList,
  setStep,
  setMetadata,
}) => {
  const { getMetadata } = useStore();

  const [loading, setLoading] = useState(false);

  const props = {
    onRemove: (file: UploadFile<RcFile>) => {
      //@ts-expect-error Не соответствуют типы
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },

    beforeUpload: (file: RcFile) => {
      const isMbtiles = file.name.includes("mbtiles");

      if (!isMbtiles) {
        notification["error"]({
          message: "Ошибка",
          description: "Неправильный формат файла",
        });
      }

      if (isMbtiles) {
        setFileList([file]);
      }
    },
    fileList,
  };

  const handleSendFile = async () => {
    setLoading(true);
    const file = fileList[0];
    const formdata = new FormData();
    formdata.append("mbtiles", file);
    const metadata = await getMetadata(formdata);
    const data = convertMetadata(metadata);
    setMetadata(data);
    setLoading(false);
    setStep(2);
  };

  return (
    <Form
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 16 }}
      className={styles.uploadContainer}
    >
      <Form.Item label="Файл" required>
        <Upload {...props} fileList={fileList} customRequest={() => {}}>
          <Button icon={<UploadOutlined />}>Загрузить</Button>
        </Upload>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
        <Button
          type="primary"
          onClick={handleSendFile}
          loading={loading}
          disabled={!fileList.length}
        >
          Далее
        </Button>
      </Form.Item>
    </Form>
  );
};

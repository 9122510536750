import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// components
import { Button, Form, Input } from "antd";

// utils
import { useStore } from "utils/store";

// types
import { ILogin } from "types/type-defs";

// styles
import styles from "./Login.module.css";

export const Login: React.FC = () => {
  const { login, loggedUser } = useStore();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loggedUser) {
      navigate("/");
    }
  }, [loggedUser, navigate]);

  const onFinish = async (values: ILogin) => {
    setLoading(true);
    await login(values);
    setLoading(false);
  };

  return (
    <div className={styles.root}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ name: "", password: "" }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Логин"
          name="username"
          rules={[{ required: true, message: "Введите логин!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          rules={[{ required: true, message: "Введите пароль!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

import { useMemo, useRef } from "react";

// components
import { MapContainer, TileLayer, Marker, ZoomControl } from "react-leaflet";

// styles
import styles from "./TilesPage.module.css";

interface IProps {
  zoom: number;
  position: {
    lat: number;
    lng: number;
  };
  setPosition: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
  >;
}

export const MapField: React.FC<IProps> = ({ zoom, position, setPosition }) => {
  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;

        if (marker != null) {
          //@ts-ignore
          let pos = marker.getLatLng();
          pos = { lat: pos.lat.toFixed(6), lng: pos.lng.toFixed(6) };
          setPosition({
            lat: parseFloat(pos.lat),
            lng: parseFloat(pos.lng),
          });
        }
      },
    }),
    [setPosition]
  );

  return (
    <MapContainer
      zoom={zoom}
      center={[position.lat, position.lng]}
      zoomControl={false}
      scrollWheelZoom={true}
      className={styles.leaflet}
    >
      <ZoomControl position="topright" />
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}
      />
    </MapContainer>
  );
};

import { useState, useMemo, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

// components
import { Button, Table, BackTop, Select } from "antd";
import { SearchBox } from "../SearchBox";
import { TableColumns } from "./TableColumns";
import { PlusOutlined } from "@ant-design/icons";
import { Loading } from "common/Loading";

// utils
import { useStore } from "utils/store";

// styles
import styles from "./UsersList.module.css";
import { GetUser } from "types";

const { Option } = Select;

export const UsersList: React.FC = () => {
  const { loggedUser, state, setState, getUsers } = useStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [access, setAccess] = useState(undefined);

  const onAddClick = () => {
    navigate("/user/create");
  };

  const downloadUsers = useCallback(async () => {
    setLoading(true);
    const usersResp: GetUser[] = await getUsers();

    const users = usersResp.reduce(
      (
        acc: {
          withName: GetUser[];
          withoutName: GetUser[];
        },
        curr
      ) =>
        curr.name
          ? { ...acc, withName: [...acc.withName, curr] }
          : { ...acc, withoutName: [...acc.withoutName, curr] },
      {
        withName: [],
        withoutName: [],
      }
    );

    const sortedUsersWithName = users.withName.sort((a, b) =>
      a.name!.localeCompare(b.name!)
    );
    const sortedUsersWithoutName = users.withoutName.sort(
      (a, b) => a.phone! - b.phone!
    );

    setState({
      ...state,
      users: [...sortedUsersWithName, ...sortedUsersWithoutName],
    });
    setLoading(false);
  }, [getUsers, setState, state]);

  const filteredUsers = useMemo(
    () =>
      state.users.filter((user) => {
        if (filter) {
          return (
            user.name?.toLowerCase().includes(filter?.toLowerCase()) ||
            user.phone?.toString().includes(filter)
          );
        }
        if (access === "web") {
          return user.role && user.login;
        }
        if (access === "mobile") {
          return user.role !== "guest" && user.role !== null && user.phone;
        }
        if (access === "tg") {
          return user.phone;
        }
        return true;
      }),
    [access, filter, state.users]
  );

  useEffect(() => {
    if (loggedUser) {
      downloadUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loggedUser) {
      navigate("/login");
    }
  }, [loggedUser, navigate]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className={styles.searchBox}>
        <SearchBox
          filter={filter}
          setFilter={setFilter}
          placeholder="ФИО или телефон"
        />

        <Select
          placeholder="Доступ"
          className={styles.select}
          allowClear
          value={access}
          onChange={(value) => setAccess(value)}
        >
          <Option value="tg">Telegram бот</Option>
          <Option value="web">Приложение Аудит</Option>
          <Option value="mobile">Мобильное приложение</Option>
        </Select>

        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddClick}
          className={styles.addButton}
        >
          {" "}
          Добавить
        </Button>
      </div>

      <Table
        columns={TableColumns}
        dataSource={filteredUsers}
        rowKey="id"
        className={styles.styledTable}
        pagination={{
          position: ["bottomLeft"],
        }}
      />

      <BackTop />
    </div>
  );
};

// components
import { Input, Button } from "antd";

// style
import style from "./SearchBox.module.css";

interface IProps {
  filter: string;
  placeholder: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  autoFocus?: boolean;
}

export const SearchBox: React.FC<IProps> = ({
  filter,
  setFilter,
  autoFocus,
  placeholder,
}) => {
  const onCancelClick = () => {
    setFilter("");
  };

  return (
    <div className={style.filterBox}>
      <Input.Search
        autoFocus={autoFocus}
        className={style.input}
        allowClear
        placeholder={placeholder}
        value={filter.toLowerCase()}
        onChange={(e) => setFilter(e.target.value)}
      />

      {filter ? (
        <Button onClick={onCancelClick} className={style.cancelButton}>
          Отмена
        </Button>
      ) : null}
    </div>
  );
};

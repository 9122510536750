/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_login_form_api_v1_token_form_post } from '../models/Body_login_form_api_v1_token_form_post';
import type { UserToken } from '../models/UserToken';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Login Form
     * Выдает токен пользователя с его правами.
     * Если return_fullname - дополнительно возвращает ФИО пользователя
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static loginFormApiV1TokenFormPost(
        formData: Body_login_form_api_v1_token_form_post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/token-form',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Login Json
     * Полностью аналогичен token-form, за исключением того что ожидает только
     * username и password в json.
     * Выдает токен пользователя с его правами.
     * @param requestBody
     * @param returnFullname
     * @returns any Successful Response
     * @throws ApiError
     */
    public static loginJsonApiV1TokenJsonPost(
        requestBody: UserToken,
        returnFullname: boolean = false,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/token-json',
            query: {
                'return_fullname': returnFullname,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Logout
     * @returns any Successful Response
     * @throws ApiError
     */
    public static logoutApiV1V1LogoutDelete(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/v1/logout',
        });
    }

}

import { useMemo, useEffect, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// components
import { PageHeader, Button, Form, Input, Switch, Select } from "antd";
import { IMaskInput } from "react-imask";
import { ApplicationSelect } from "./ApplicationSelect";

// utils
import { useStore } from "utils/store";

// types
import { CreateUser } from "types";

// styles
import styles from "./UserPage.module.css";

const { Option } = Select;

export const UserPage: React.FC = () => {
  const { loggedUser, state, setState, updateUser, createUser, getPlaces } =
    useStore();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [application, setApplication] = useState("tg");

  const downloadPlaces = useCallback(async () => {
    const places = await getPlaces();
    setState({ ...state, places });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPlaces, setState]);

  useEffect(() => {
    if (!loggedUser) {
      navigate("/login");
    } else {
      downloadPlaces();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  const isCreate = id === "create";

  const currentUser = useMemo(
    () => state.users.find((user) => user.id.toString() === id),
    [id, state.users]
  );

  const places = useMemo(
    () =>
      state.places
        .map((place) => ({
          value: place.place_id,
          label: place.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [state.places]
  );

  const initialValues = {
    name: currentUser?.name ?? "",
    phone: currentUser?.phone?.toString() ?? "",
    is_admin: currentUser?.is_admin ?? false,
    login: currentUser?.login ?? "",
    role: currentUser?.role ?? "",
    bot_places: currentUser?.bot_places ?? [],
    places: currentUser?.places ?? [],
    password: "",
  };

  const onArrowButtonClick = () => {
    navigate("/");
  };

  const onSubmit = async (values: typeof initialValues) => {
    const { phone, login, password, role, ...rest } = values;
    const user: CreateUser = {
      ...rest,
    };

    if (phone) {
      user.phone = parseInt(phone.replace(/[^0-9]/g, ""), 10);
    }

    if (password && login) {
      user.password = password;
      user.login = login;
    }

    if (role) {
      user.role = role;
    }

    if (isCreate) {
      await createUser(user);
    } else {
      await updateUser({ userId: parseInt(id!, 10), user });
    }
    navigate("/");
  };

  const loginValue = Form.useWatch("login", form);
  const phoneValue = Form.useWatch("phone", form);

  return (
    <div>
      <PageHeader
        onBack={onArrowButtonClick}
        title={`${isCreate ? "Создание" : "Редактирование"} пользователя`}
        className={styles.header}
      />

      <br />

      <Form
        name="user"
        form={form}
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 6 }}
        initialValues={initialValues}
        onFinish={onSubmit}
        autoComplete="off"
      >
        <Form.Item label="Приложение">
          <ApplicationSelect
            application={application}
            setApplication={setApplication}
          />
        </Form.Item>

        <Form.Item
          label="ФИО"
          name="name"
          rules={[{ required: true, message: "Введите ФИО!" }]}
        >
          <Input />
        </Form.Item>

        {application === "web" ? (
          <>
            <Form.Item
              label="Логин"
              name="login"
              rules={[{ required: !phoneValue, message: "Введите логин!" }]}
            >
              <Input autoComplete="off" />
            </Form.Item>

            <Form.Item
              label="Пароль"
              name="password"
              rules={[
                {
                  required: isCreate,
                  message: "Введите пароль!",
                },
              ]}
            >
              <Input type="password" autoComplete="off" />
            </Form.Item>
          </>
        ) : null}

        {application !== "web" ? (
          <Form.Item
            label="Телефон"
            name="phone"
            rules={[{ required: !loginValue, message: "Введите телефон!" }]}
          >
            <div className="ant-input-wrapper ant-input-group">
              <IMaskInput
                mask={"+0 (000) 000-00-00"}
                value={form.getFieldValue("phone")}
                unmask={true}
                className="ant-input"
                onAccept={(value: any) => {
                  form.setFieldsValue({ phone: value.replace(/[^0-9]/g, "") });
                }}
                placeholder="+_ (___) ___-__-__"
              />
            </div>
          </Form.Item>
        ) : null}

        {application !== "tg" ? (
          <Form.Item
            label="Роль"
            name="role"
            rules={[{ required: true, message: "Необходимо выбрать роль!" }]}
          >
            <Select className={styles.select} allowClear>
              <Option value="auditor">Аудитор</Option>
              {application === "web" && <Option value="guest">Гость</Option>}
              <Option value="registrator">Регистратор</Option>
              <Option value="installer">Установщик</Option>
            </Select>
          </Form.Item>
        ) : null}

        <Form.Item
          label="Объекты"
          name={application !== "web" ? "bot_places" : "places"}
        >
          <Select
            className={styles.select}
            options={places}
            allowClear
            showSearch
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.label as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          name="is_admin"
          label="Администратор"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 2, span: 6 }}>
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

// components
import {
  MobileOutlined,
  DesktopOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { DeleteButton } from "./DeleteButton";
import { EditButton } from "./EditButton";

// types
import { GetUser } from "types";
import { ColumnsType } from "antd/es/table";

// styles
import styles from "./UsersList.module.css";

export const TableColumns: ColumnsType<GetUser> = [
  {
    title: "Имя",
    dataIndex: "name",
  },
  {
    title: "Телефон",
    dataIndex: "phone",
  },
  {
    title: (
      <svg
        width="14px"
        height="16px"
        viewBox="0 0 15 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5 1.5L0.5 6.5L4.5 8.5L10.5 4.5L6.5 9.5L12.5 13.5L14.5 1.5Z"
          stroke="black"
          strokeLinejoin="round"
        />
      </svg>
    ),
    dataIndex: "phone",
    align: "center",
    render: (phone) =>
      phone ? <CheckCircleOutlined className={styles.icon} /> : null,
  },
  {
    title: <DesktopOutlined />,
    dataIndex: "id",
    align: "center",
    render: (_, user) =>
      user.role && user.login ? (
        <CheckCircleOutlined className={styles.icon} />
      ) : null,
  },
  {
    title: <MobileOutlined />,
    dataIndex: "id",
    align: "center",
    render: (_, user) =>
      user.role !== "guest" && user.role !== null && user.phone ? (
        <CheckCircleOutlined className={styles.icon} />
      ) : null,
  },
  {
    dataIndex: "id",
    render: (id) => <EditButton id={id} />,
  },
  {
    dataIndex: "id",
    render: (id) => <DeleteButton id={id} />,
  },
];

import { useState } from "react";

// components
import { Button, Popconfirm } from "antd";

// utils
import { useStore } from "utils/store";

interface IProps {
  id: number;
}

export const DeleteButton: React.FC<IProps> = ({ id }) => {
  const { state, setState, deleteLink } = useStore();
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    await deleteLink(id);
    const links = state.links.map((link) =>
      id === link.place_id ? { ...link, url: null } : link
    );
    setState({ ...state, links });
    setLoading(false);
  };

  return (
    <Popconfirm
      title="Вы действительно хотите удалить ссылку?"
      onConfirm={handleDelete}
      okText="Да"
      cancelText="Нет"
    >
      <Button type="link" danger loading={loading}>
        Удалить
      </Button>
    </Popconfirm>
  );
};

// components
import { Radio } from "antd";

interface IProps {
  application: string;
  setApplication: React.Dispatch<React.SetStateAction<string>>;
}

export const ApplicationSelect: React.FC<IProps> = ({
  application,
  setApplication,
}) => {
  const options = [
    { label: "Telegram бот", value: "tg" },
    { label: "Аудит", value: "web" },
    { label: "Мобильное приложение", value: "mobile" },
  ];

  return (
    <Radio.Group
      value={application}
      options={options}
      optionType="button"
      buttonStyle="solid"
      onChange={(e) => setApplication(e.target.value)}
    />
  );
};

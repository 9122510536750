import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// components
import { Table, Button, BackTop } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { SearchBox } from "users/SearchBox";
import { Loading } from "common/Loading";
import { LinkModal } from "./LinkModal";
import { TableColumns } from "./TableColumns";

// utils
import { useStore } from "utils/store";

// styles
import styles from "./MapPage.module.css";

// types
import { ILink } from "types/type-defs";

export const MapPage: React.FC = () => {
  const { loggedUser, state, setState, getPlaces, getLinks } = useStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [visible, setVisible] = useState(false);

  const download = async () => {
    const places = await getPlaces();
    const links = await getLinks();
    setState({ ...state, places, links });
    setLoading(false);
  };

  useEffect(() => {
    if (!loggedUser) {
      navigate("/login");
    } else {
      download();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  const onAddClick = () => {
    setVisible(true);
  };

  if (loading) {
    return <Loading />;
  }

  const filteredLinks = state.links
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((link) =>
      link.name.toLocaleLowerCase().includes(filter?.toLocaleLowerCase())
    );

  return (
    <div>
      <div className={styles.searchBox}>
        <SearchBox filter={filter} setFilter={setFilter} placeholder="Объект" />

        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddClick}
          className={styles.addButton}
        >
          {" "}
          Добавить
        </Button>
      </div>

      <Table<ILink>
        columns={TableColumns}
        dataSource={filteredLinks}
        rowKey="place_id"
        className={styles.styledTable}
        pagination={{
          position: ["bottomLeft"],
        }}
      />

      <LinkModal visible={visible} setVisible={setVisible} />

      <BackTop />
    </div>
  );
};

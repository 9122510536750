import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// components
import { Menu, MenuProps } from "antd";

// styles
import styles from "./MainMenu.module.css";

type MenuItem = Required<MenuProps>["items"][number];

export const MainMenu: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);

  const onKeyClick = ({ key }: { key: string }) => {
    navigate(key);
    setSelectedKeys([key]);
  };

  const items: MenuItem[] = [
    { label: "Пользователи", key: "/" },
    { label: "Карта", key: "/map" },
    { label: "Оффлайн карты", key: "/tiles" },
  ];

  return (
    <Menu
      mode="horizontal"
      selectedKeys={selectedKeys}
      onClick={onKeyClick}
      items={items}
      className={styles.menu}
    />
  );
};

import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

// components
import { Steps } from "antd";
import { ImportForm } from "./ImportForm";
import { ConvertPage } from "./ConvertPage";

// utils
import { useStore } from "utils/store";

// styles
import styles from "./TilesPage.module.css";

// types
import { IMetadata } from "types/type-defs";
import { RcFile } from "antd/lib/upload";

const { Step } = Steps;

export const TilesPage: React.FC = () => {
  const { loggedUser, state, setState, getPlaces } = useStore();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [metadata, setMetadata] = useState<IMetadata | null>(null);
  const [fileList, setFileList] = useState<Array<RcFile>>([]);

  const downloadPlaces = useCallback(async () => {
    const places = await getPlaces();
    setState({ ...state, places });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPlaces, setState]);

  useEffect(() => {
    if (!loggedUser) {
      navigate("/login");
    } else {
      downloadPlaces();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser, navigate]);

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <ImportForm
            setStep={setStep}
            setMetadata={setMetadata}
            fileList={fileList}
            setFileList={setFileList}
          />
        );
      case 2:
        return <ConvertPage metadata={metadata!} fileList={fileList} />;
      default:
        break;
    }
  };

  return (
    <div className={styles.container}>
      <Steps current={step} className={styles.steps}>
        <Step title="Импорт" />
        <Step title="Результат" />
      </Steps>

      {renderContent()}
    </div>
  );
};

// components
import { Typography } from "antd";
import { DeleteButton } from "./DeleteButton";

// types
import { ILink } from "types/type-defs";
import { ColumnsType } from "antd/es/table";

// styles
import styles from "./MapPage.module.css";

const { Paragraph, Link } = Typography;

export const TableColumns: ColumnsType<ILink> = [
  {
    title: "Объект",
    dataIndex: "name",
  },
  {
    title: "Ссылка",
    dataIndex: "url",
    render: (url) =>
      url ? (
        <Link href={url} target="_blank">
          <Paragraph copyable ellipsis className={styles.url}>
            {url}
          </Paragraph>
        </Link>
      ) : null,
  },
  {
    dataIndex: "place_id",
    render: (place_id) => <DeleteButton id={place_id} />,
  },
];

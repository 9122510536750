import { request } from "./request";

// components
import { message } from "antd";

// types
import { AuthService, CreateUser } from "types";
import { ILogin } from "../types/type-defs";

// авторизация пользователя
const login = async ({
  username,
  password,
  setLoggedUser,
  handleError,
}: ILogin & {
  setLoggedUser: React.Dispatch<React.SetStateAction<string>>;
  handleError: (resp: Response) => void;
}) => {
  try {
    const body = JSON.stringify({
      username,
      password,
    });

    const resp = await request(
      "/api/v1/token-json?return_fullname=true",
      "POST",
      body
    );

    if (resp.status === 401) {
      message.error("Неправильный логин или пароль");
    }

    if (!resp.ok) {
      return handleError(resp);
    }

    const data = await resp.json();
    setLoggedUser(username);
    localStorage.setItem("user", username);

    return data;
  } catch (error) {
    console.log(error);
  }
};

// Выход из системы
const logout = async (
  setLoggedUser: React.Dispatch<React.SetStateAction<string>>
) => {
  try {
    AuthService.logoutApiV1V1LogoutDelete().then(() => {
      setLoggedUser("");
      localStorage.removeItem("user");
    });
  } catch (error) {
    console.log(error);
  }
};

// Получаем список пользователей
const getUsers = async (handleError: (resp: Response) => void) => {
  try {
    const resp = await request("/api/v1/users", "GET");

    if (!resp.ok) {
      return handleError(resp);
    }
    const data = resp.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Удаление пользователя
const deleteUser = async ({
  userId,
  handleError,
}: {
  userId: number;
  handleError: (resp: Response) => void;
}) => {
  try {
    const resp = await request(`/api/v1/users?user_id=${userId}`, "DELETE");

    if (!resp.ok) {
      return handleError(resp);
    }
    return userId;
  } catch (error) {
    console.log(error);
  }
};

// Редактирвание пользователя
const updateUser = async ({
  userId,
  user,
  handleError,
}: {
  userId: number;
  user: CreateUser;
  handleError: (resp: Response) => void;
}) => {
  const body = JSON.stringify(user);
  try {
    const resp = await request(`/api/v1/users?user_id=${userId}`, "PUT", body);

    if (!resp.ok) {
      return handleError(resp);
    }
    return userId;
  } catch (error) {
    console.log(error);
  }
};

// Создание пользователя
const createUser = async ({
  user,
  handleError,
}: {
  user: CreateUser;
  handleError: (resp: Response) => void;
}) => {
  const body = JSON.stringify(user);
  try {
    const resp = await request(`/api/v1/users`, "POST", body);

    if (!resp.ok) {
      return handleError(resp);
    }
    return null;
  } catch (error) {}
};

// Загрузка списка мест
const getPlaces = async (handleError: (resp: Response) => void) => {
  try {
    const resp = await request(`/api/v1/places`, "GET");

    if (!resp.ok) {
      return handleError(resp);
    }
    const data = resp.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Загрузка списка ссылок
const getLinks = async (handleError: (resp: Response) => void) => {
  try {
    const resp = await request(`/user_management/get_links`, "GET");

    if (!resp.ok) {
      return handleError(resp);
    }
    const data = resp.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Создать ссылку
const createLink = async (
  place_id: number,
  handleError: (resp: Response) => void
) => {
  try {
    const resp = await request(
      `/user_management/create_links?place_id=${place_id}`,
      "POST"
    );

    if (!resp.ok) {
      return handleError(resp);
    }
    const data = resp.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Удалить ссылку
const deleteLink = async (
  place_id: number,
  handleError: (resp: Response) => void
) => {
  try {
    const resp = await request(
      `/user_management/delete_links?place_id=${place_id}`,
      "DELETE"
    );

    if (!resp.ok) {
      return handleError(resp);
    }
    return place_id;
  } catch (error) {
    console.log(error);
  }
};

// Получение информации из файлa mbtiles
const getMetadata = async (
  mbtiles: FormData,
  handleError: (resp: Response) => void
) => {
  try {
    const resp = await fetch("/map-utils/get-mbtiles-metadata", {
      method: "POST",
      body: mbtiles,
    });

    if (!resp.ok) {
      return handleError(resp);
    }
    const data = resp.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

// Конвертировать mbtiles-файл в архив png-тайлов.
const convertMbtiles = async (
  formdata: FormData,
  handleError: (resp: Response) => void
) => {
  try {
    const resp = await fetch("/map-utils/convert-mbtiles-to-png", {
      method: "POST",
      body: formdata,
    });

    if (!resp.ok) {
      return handleError(resp);
    }
    const data = resp.blob();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const api = {
  login,
  logout,
  getUsers,
  deleteUser,
  updateUser,
  createUser,
  getPlaces,
  getLinks,
  createLink,
  deleteLink,
  getMetadata,
  convertMbtiles,
};

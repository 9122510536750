import { useNavigate } from "react-router-dom";

// components
import { Button } from "antd";

interface IProps {
  id: number;
}

export const EditButton: React.FC<IProps> = ({ id }) => {
  const navigate = useNavigate();

  const handleEdit = async () => {
    navigate(`/user/${id}`);
  };

  return (
    <Button type="link" onClick={handleEdit}>
      Изменить
    </Button>
  );
};

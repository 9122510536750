// styles
import "antd/dist/antd.min.css";

// components
import { ConfigProvider } from "antd";
import { Routes } from "./Routes";

// locale
import ruRu from "antd/lib/locale/ru_RU";

export const App: React.FC = () => {
  return (
    <ConfigProvider locale={ruRu}>
      <Routes />
    </ConfigProvider>
  );
};

import { Routes as Router, Route } from "react-router-dom";

// components
import { Login } from "../auth/Login";
import { UsersList, UserPage } from "../users";
import { MapPage } from "../map";
import { TilesPage } from "../tiles/TilesPage";

export const Routes: React.FC = () => {
  return (
    <Router>
      <Route path="/login" element={<Login />} />
      <Route path="/user/:id" element={<UserPage />} />
      <Route path="/map" element={<MapPage />} />
      <Route path="/tiles" element={<TilesPage />} />
      <Route path="/" element={<UsersList />} />
    </Router>
  );
};

import { useMemo, useState } from "react";

// components
import { Modal, Select } from "antd";

// utils
import { useStore } from "utils/store";

// styles
import styles from "./MapPage.module.css";

interface IProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LinkModal: React.FC<IProps> = ({ visible, setVisible }) => {
  const { state, createLink, setState, getLinks } = useStore();

  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState(null);

  const places = useMemo(
    () =>
      state.places
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((place) => ({
          value: place.place_id,
          label: place.name,
        })),
    [state.places]
  );

  const updateLinks = async () => {
    const links = await getLinks();
    setState({ ...state, links });
    setLoading(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSave = async () => {
    if (place) {
      setLoading(true);
      await createLink(place);
      await updateLinks();
      setPlace(null);
      setVisible(false);
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Создание ссылки"
      visible={visible}
      onOk={handleSave}
      okButtonProps={{
        disabled: !place,
      }}
      okType="primary"
      onCancel={handleCancel}
      confirmLoading={loading}
    >
      Объект:{" "}
      <Select
        value={place}
        onChange={(e) => setPlace(e)}
        className={styles.select}
        options={places}
        allowClear
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option!.label as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      />
    </Modal>
  );
};

// components
import { Button, Popconfirm } from "antd";

// utils
import { useStore } from "utils/store";

interface IProps {
  id: number;
}

export const DeleteButton: React.FC<IProps> = ({ id }) => {
  const { state, setState, deleteUser } = useStore();

  const handleDelete = async () => {
    const userId = await deleteUser(id);
    if (userId) {
      const users = state.users.filter(({ id }) => id !== userId);
      setState({ ...state, users });
    }
  };

  return (
    <Popconfirm
      title="Вы действительно хотите удалить этого пользователя?"
      onConfirm={handleDelete}
      okText="Да"
      cancelText="Нет"
    >
      <Button type="link" danger>
        Удалить
      </Button>
    </Popconfirm>
  );
};

export const request = (fetchUrl: string, method: string, body?: any) => {
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    body,
  };

  return fetch(fetchUrl, options);
};

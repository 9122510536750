import { useLocation } from "react-router-dom";

// components
import { Layout } from "antd";
import { MainMenu } from "common/MainMenu";

// utils
import { useStore } from "utils/store";

// styles
import styles from "./AppContainer.module.css";

interface IProps {
  children: React.ReactNode;
}

const { Header, Content } = Layout;

const pagesWithMenu = ["/", "/map", "/tiles"];

export const AppContainer: React.FC<IProps> = ({ children }) => {
  const { loggedUser, logout } = useStore();
  const location = useLocation();

  const isVisibleMenu = pagesWithMenu.includes(location.pathname);

  const handleLogout = () => {
    logout();
  };

  return (
    <Layout className={styles.root}>
      <Header className={styles.header}>
        <h2 className={styles.title}>Управление доступом</h2>

        {loggedUser ? (
          <div className={styles.exit} onClick={handleLogout}>
            Выход
          </div>
        ) : null}
      </Header>

      <Content>
        {isVisibleMenu ? <MainMenu /> : null}
        <div className={styles.content}>{children}</div>
      </Content>
    </Layout>
  );
};

import { useMemo, useState } from "react";

// components
import { Button, Form, Input, Select } from "antd";
import { MapField } from "./MapField";

// utils
import { useStore } from "utils/store";

// styles
import styles from "./TilesPage.module.css";

// types
import { IMetadata } from "types/type-defs";
import { RcFile } from "antd/lib/upload";

interface IProps {
  fileList: Array<RcFile>;
  metadata: IMetadata;
}

export const ConvertPage: React.FC<IProps> = ({ fileList, metadata }) => {
  const { convertMbtiles, state } = useStore();
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState<{ lat: number; lng: number }>({
    lat: metadata.latitude,
    lng: metadata.longitude,
  });

  const places = useMemo(
    () =>
      state.places
        .map((place) => ({
          value: place.place_id,
          label: place.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [state.places]
  );

  const onSubmit = async ({
    name,
    place_id,
  }: {
    name: string;
    place_id: number[];
  }) => {
    setLoading(true);
    const file = fileList[0];
    const formdata = new FormData();
    formdata.append("mbtiles", file);

    formdata.append(
      "metadata",
      JSON.stringify({
        name,
        latitude: position.lat,
        longitude: position.lng,
        minzoom: metadata.minzoom,
        maxzoom: metadata.maxzoom,
        place_id: place_id[0],
      })
    );
    const data = await convertMbtiles(formdata);

    var xlsxURL = window.URL.createObjectURL(data);
    let tempLink = document.createElement("a");
    tempLink.href = xlsxURL;
    tempLink.setAttribute("download", `${name}.map`);
    tempLink.click();

    setLoading(false);
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ name: metadata?.name }}
      onFinish={onSubmit}
      className={styles.convertForm}
      autoComplete="off"
    >
      <Form.Item
        label="Название"
        name="name"
        rules={[{ required: true, message: "Введите название!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Регион"
        name="place_id"
        rules={[{ required: true, message: "Выберите объект!" }]}
      >
        <Select
          className={styles.select}
          options={places}
          allowClear
          showSearch
          mode="multiple"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.label as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item
        label="Регион"
        name="name"
        rules={[{ required: true, message: "Укажите регион!" }]}
      >
        <MapField
          position={position}
          setPosition={setPosition}
          zoom={metadata.maxzoom}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 5, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          Загрузить
        </Button>
      </Form.Item>
    </Form>
  );
};
